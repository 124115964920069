// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("C:\\Users\\Lokesh\\FINSHOTS-FRONTEND\\archive-finshots\\src\\templates\\page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("C:\\Users\\Lokesh\\FINSHOTS-FRONTEND\\archive-finshots\\src\\templates\\post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-index-js": () => import("C:\\Users\\Lokesh\\FINSHOTS-FRONTEND\\archive-finshots\\src\\templates\\index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Lokesh\\FINSHOTS-FRONTEND\\archive-finshots\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

