module.exports = [{
      plugin: require('C:/Users/Lokesh/FINSHOTS-FRONTEND/archive-finshots/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-120539095-5","head":true},
    },{
      plugin: require('C:/Users/Lokesh/FINSHOTS-FRONTEND/archive-finshots/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Lokesh/FINSHOTS-FRONTEND/archive-finshots/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
